const colors = {
  red: '#E51D2E',
  darkRed: '#8D0000',
  winnerRed: '#e51e2e',
  runnerUpTitle: '#ae1117',
  white: '#FFFFFF',
  black: '#000000',
  dropdownItemHover: '#F4F4F4',
  dropDownBorder: '#707070',
  tableHeader: '#727272',
  championshipBackground: '#D9B86E',
  standingsBorder: '#D9B86E',
  blue: '#2B4898',
  dropDownGradient: 'linear-gradient(to right, #ff0000 0%, #ff8900 21%, #fcf200 41%, #26a200 59%, #1e2bc9 77%, #6e009f 100%, rgba(128, 128, 128, 0) 100%)',
  signUpButton: 'linear-gradient(to right, #ff0000 0%, #26a200 0%, #1e2bc9 51%, #6e009f 100%, rgba(128, 128, 128, 0) 100%)',
  voteHereButton: 'linear-gradient(to right, #ff0000 0%, #ff8900 49%, #fcf200 100%, rgba(128, 128, 128, 0) 100%)',
  cardCharity: '#0a2257',
  cardLine: '#dddddd',
};

export default colors;
