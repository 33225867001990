import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import Logo from '../assets/svg/logo-stoli-white.svg';
import colors from '../utils/colors';
import breakpoints from '../utils/breakpoints';

const Footer = ({ style, blur }) => (
  <Background style={style} className={blur && 'blur'}>
    <StyledLogo />
    <ContentWrapper>
      <Description>
        <Drink>DRINK WITH CARE.</Drink>
        <StoliLocation>Stoli Group USA, LLC, New York, NY.</StoliLocation>
        ™ and ® – STOLI, STOLICHNAYA and MAKE IT LOUD AND CLEAR
        are trademarks or registered trademarks, of, depending on the country, ZHS IP Americas Sàrl,
        ZHS IP Europe Sàrl, ZHS IP Worldwide Sàrl, or Spirits International B.V. © 2018. All rights
        reserved.
      </Description>
      <LinkContainer>
        <Link href="https://stoli.com/">Main Site</Link>
        <Link href="https://stoli.com/privacy-policy/">Privacy Policy</Link>
        <Link href="https://stoli.com/terms-conditions/">Terms & Conditions</Link>
      </LinkContainer>
    </ContentWrapper>
  </Background>
);

const Background = styled.div`
  width: 100%;
  background-color: ${colors.red};
  position: relative;
`;

const StyledLogo = styled(Logo)`
  max-width: 8.8541666667vw;
  position: absolute;

  @media (max-width: ${breakpoints.md.max}px) {
    max-width: 27.734375vw;
  }

  @media (max-width: ${breakpoints.xs.max}px) {
    max-width: 33.0666666667vw;
  }
`;

const ContentWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 6.7708333333vw 2.0833333333vw 1.5625vw 4.1666666667vw;

  @media (max-width: ${breakpoints.md.max}px) {
    padding: 20.1822916667vw 2.0833333333vw 1.5625vw 4.1666666667vw;
  }

  @media (max-width: ${breakpoints.xs.max}px) {
    flex-direction: column;
  }
`;

const Description = styled.div`
  max-width: 61.782609vw;
  margin-right: 40px;
  color: white;
  font-family: 'Tahoma';
  font-size: 0.9375vw;
  line-height: 1.302083vw;

  @media (max-width: ${breakpoints.md.max}px) {
    max-width: 50.5208333333vw;
    font-size: 1.8229166667vw;
    line-height: 2.34375vw;
  }

  @media (max-width: ${breakpoints.xs.max}px) {
    order: 1;
    max-width: 90.1333333333vw;
    margin-right: 0;
    margin-bottom: 15.4782608696vw;
    font-size: 2.4vw;
    line-height: 3.2vw;
  }
`;

const Drink = styled.div`
  font-family: 'Tahoma-Bold';
  font-size: 0.9375vw;

  @media (max-width: ${breakpoints.md.max}px) {
    font-size: 1.8229166667vw;
    line-height: 2.34375vw;
  }

  @media (max-width: ${breakpoints.xs.max}px) {
    font-size: 2.4vw;
    line-height: 3.2vw;
  }
`;

const StoliLocation = styled.div``;

const LinkContainer = styled.div`
  display: flex;
  flex-direction: column;
  min-width: 9.375vw;

  @media (max-width: ${breakpoints.xs.max}px) {
    align-items: center;
    margin-bottom: 6.9333333333vw;
  }
`;

const Link = styled.a`
  text-align: left;
  text-transform: uppercase;
  margin-bottom: 0.5208333333vw;
  color: white;
  text-decoration: none;
  font-size: 0.9375vw;
  font-family: 'Tahoma-Bold';

  @media (max-width: ${breakpoints.md.max}px) {
    font-size: 1.8229166667vw;
    line-height: 3.90625vw;
  }

  @media (max-width: ${breakpoints.xs.max}px) {
    font-size: 1.8229166667vw;
    line-height: 3.90625vw;
  }
`;

Footer.propTypes = {
  style: PropTypes.object,
  blur: PropTypes.bool,
};

Footer.defaultProps = {
  style: {},
  blur: false,
};

export default Footer;
