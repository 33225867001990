import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import Logo from '../assets/images/logo-stoli-white.svg';
import colors from '../utils/colors';

const StoliBackground = ({ children, style }) => (
  <Background style={style}>
    {children}
  </Background>
);

const Background = styled.div`
  background-image: url(${Logo});
  background-color: ${colors.red};
  background-size: 33% auto;
  background-repeat: no-repeat;
`;

StoliBackground.propTypes = {
  children: PropTypes.any,
  style: PropTypes.object,
};

StoliBackground.defaultProps = {
  style: {},
};


export default StoliBackground;
