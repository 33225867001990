import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import breakpoints from '../utils/breakpoints';
import colors from '../utils/colors';
import '../styles/global.css';

const Button = ({ label, onClick, style, type }) => (
  <StyledButton onClick={onClick} style={style} type={type}>{label}</StyledButton>
);

const StyledButton = styled.button`
  border: none;
  background-color: #fff;
  margin: 0.5208333333vw 1.1979166667vw;
  padding: 0.7291666667vw 3.6458333333vw;
  border-radius: 30px;
  color: ${colors.darkRed};
  font-family: 'Tahoma-Bold';
  font-size: 1.3020833333vw;
  font-weight: 700;
  line-height: 1.3020833333vw;
  box-shadow: 0 0 1.0416666667vw 0.05208333333vw rgba(53,53,53,.5);
  cursor: pointer;
  outline-color: ${colors.darkRed};

  @media (max-width: ${breakpoints.md.max}px) {
    font-size: 2.0833333333vw;
    line-height: 2.0833333333vw;
    padding: 1.3913043478vw 5.5652173913vw;
  }

  @media (max-width: ${breakpoints.xs.max}px) {
    font-size: 2.2608695652vw;
    line-height: 2.0833333333vw;
    padding: 1.3913043478vw 5.5652173913vw;
  }
`;

Button.propTypes = {
  label: PropTypes.string,
  type: PropTypes.string,
  onClick: PropTypes.func,
  style: PropTypes.object,
};

Button.defaultProps = {
  label: '',
  type: '',
  onClick: () => {},
  style: {},
};


export default Button;
