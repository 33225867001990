import React, { Component } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import StoliBackground from './StoliBackground';
import Button from './Button';
import ModalFooter from './ModalFooter';
import breakpoints from '../utils/breakpoints';
import colors from '../utils/colors';

const customStyles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    transform: 'translate(-50%, -50%)',
    padding: 0,
    border: 'none',
    borderRadius: 0,
    boxShadow: '0 0 0.78125vw 0.05208333333vw rgba(53,53,53,.5)',
    width: '90%',
    maxWidth: '46.9791666667vw',
  },
};

const styles = {
  button: {
    display: 'block',
    marginButton: '1rem',
  },
};

class BirthDateModal extends Component {
  state = { acceptTerms: false, isMinor: false }

  toggleCheckbox = () => {
    this.setState(prevState => ({ acceptTerms: !prevState.acceptTerms }));
  }

  renderForm = () => {
    const { acceptTerms } = this.state;
    return (
      <Form onSubmit={this.handleSubmit}>
        <ConfirmAge>
          CONFIRM THAT YOU ARE OF LEGAL
          DRINKING AGE IN YOUR COUNTRY
        </ConfirmAge>

        <ButtonWrapper>
          <Button label="Yes" style={styles.button} type="submit" />
          <Button label="No" style={styles.button} onClick={this.handleReject} />
        </ButtonWrapper>
        <FooterWrapper>
          <ModalFooter checked={acceptTerms} onClick={this.toggleCheckbox} />
        </FooterWrapper>
      </Form>
    );
  }

  renderLegal = () => {
    return (
      <Legal>
        <Link href="http://www.talkaboutalcohol.com/">
          You Must Be Of Legal Drinking Age
          to Enter this Site
        </Link>
      </Legal>
    );
  }

  handleSubmit = (event) => {
    const { onSubmit } = this.props;
    event.preventDefault();
    onSubmit();
  }

  handleReject = (event) => {
    event.preventDefault();
    window.location = 'http://www.talkaboutalcohol.com/';
  }

  render() {
    const { isMinor } = this.state;
    return (
      <Modal
        isOpen
        style={customStyles}
        overlayClassName="overlay"
      >
        <StoliBackground>
          <ModalContent>
            {isMinor ? this.renderLegal() : this.renderForm()}
          </ModalContent>
        </StoliBackground>
      </Modal>
    );
  }
}

const Modal = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  max-width: 46.9791666667vw;
  min-height: 28.6979166667vw;

  @media (max-width: ${breakpoints.md.max}px) {
    min-width: 79.9479166667vw;
    min-height: 48.9583333333vw;
  }

  @media (max-width: ${breakpoints.xs.max}px) {
    min-width: 54.4347826087vw;
    min-height: 63.4782608696vw;
  }
`;

const ModalContent = styled.div`
  flex: 1;
  width: 100%;
  padding: 1rem;
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const ConfirmAge = styled.div`
  flex: 1;
  margin: 10.8333333333vw 0 2.65625vw 0;
  padding: 0 5.239583vw;
  color: ${colors.white};
  font-family: 'Tahoma-Bold';
  font-size: 1.8229166667vw;
  font-weight: 700;
  line-height: 2.34375vw;
  text-transform: uppercase;

  @media (max-width: ${breakpoints.md.max}px) {
    padding: 0 10.020833vw;
    font-size: 2.9947916667vw;
    line-height: 3.90625vw;
  }

  @media (max-width: ${breakpoints.xs.max}px) {
    margin: 15.73913vw 0 6.9565217391vw 0;
    padding: 0 3.5652173913vw;
    font-size: 3.1304347826vw;
    line-height: 4.5217391304vw;
  }
`;

const ButtonWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

const Legal = styled.div`
  margin-top: 10rem;
  font-size: 1.1458333333vw;
  color: white;
  font-family: 'Tahoma';
  text-align: center;
  padding: 0 3rem;

  @media (max-width: ${breakpoints.sm.max}px) {
    font-size: 1.1458333333vw;
    padding: 0;
  }
`;

const Link = styled.a`
  color: white;
  text-decoration: none;
  cursor: pointer;
`;

const FooterWrapper = styled.div``;

const Form = styled.form`
  margin: 0;
  display: block;
`;

BirthDateModal.propTypes = {
  onSubmit: PropTypes.func,
};

BirthDateModal.defaultProps = {
  onSubmit: () => { },
};

export default BirthDateModal;
