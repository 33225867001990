import moment from 'moment-timezone';

export const todayFormatted = moment.tz('America/New_York');
export const startDate = moment.tz('2019-05-22 15:00', 'America/New_York');
export const endDate = moment.tz('2019-05-30 15:00', 'America/New_York');
export const finale = moment.tz('2019-06-03 5:00', 'America/New_York');

export default {
  todayFormatted,
  startDate,
  endDate,
  finale,
};
