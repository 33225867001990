import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import colors from '../utils/colors';
import breakpoints from '../utils/breakpoints';

const ModalFooter = ({ checked, onClick }) => (
  <Wrapper>
    <Label htmlFor="acceptTerms" onClick={onClick}>
      <Checkbox type="checkbox" name="acceptTerms" checked={checked} required onChange={() => { }} />
      {checked ? <Check /> : <Square />}
      <Text>
        By entering this site, I agree to its&nbsp;
        <Link
          href="https://stoli.com/terms-conditions/"
          target="_blank"
          rel="noopener noreferrer"
          onClick={(e) => { e.stopPropagation(); }}
        >
          terms & conditions
        </Link>
        &nbsp;and&nbsp;
        <Link
          href="https://stoli.com/privacy-policy/"
          target="_blank"
          rel="noopener noreferrer"
          onClick={(e) => { e.stopPropagation(); }}
        >
          privacy policy
        </Link>
        .
      </Text>
    </Label>
  </Wrapper>
);

const Wrapper = styled.div`
  position: relative;
  margin: 4.3229166667vw 0 1.875vw 0;

  @media (max-width: ${breakpoints.md.max}px) {
    margin: 7.03125vw 0 0 0;
  }
`;

const Label = styled.label`
  display: block;
  position: relative;
  cursor: pointer;
  font-size: 0.6770833333vw;
  line-height: 0.9895833333vw;
  font-family: 'Tahoma';
  font-weight: 300;
  text-align: left;
  color: ${colors.white};
`;

const Checkbox = styled.input`
  position: absolute;
  top: 0;
  left: 0;
  opacity: 0;
  margin: 0;
  cursor: pointer;
`;

const Square = styled.div`
  width: 1.5625vw;
  height: 1.5625vw;
  position: absolute;
  top: 0;
  left: 0.2604166667vw;
  background-color: transparent;
  border: 1px solid white;

  @media (max-width: ${breakpoints.md.max}px) {
    height: 2.6041666667vw;
    width: 2.6041666667vw; 
  }
`;

const Check = styled.div`
  content: "";
  position: absolute;
  left: 0.5729166667vw;
  top: -2px;
  width: 1.0416666667vw;
  height: 1.0416666667vw;
  border: solid white;
  border-width: 0 1px 1px 0;
  transform: rotate(45deg);
`;

const Text = styled.span`
  display: inline-block;
  margin-left: 3.125vw;
  color: #ffffff;
  font-family: Verdana, sans-serif;
  font-size: 0.9375vw;
  font-weight: 400;
  line-height: 1.3020833333vw;

  @media (max-width: ${breakpoints.md.max}px) {
    margin-left: 5.125vw;
    font-size: 1.5625vw;
    line-height: 2.2135416667vw;
  }

  @media (max-width: ${breakpoints.xs.max}px) {
    font-size: 2.0869565217vw;
    line-height: 2.9565217391vw;
  }
`;

const Link = styled.a`
  color: ${colors.white};
  cursor: pointer;
  text-decoration: underline;
`;

ModalFooter.propTypes = {
  checked: PropTypes.bool,
  onClick: PropTypes.func,
};

ModalFooter.defaultProps = {
  checked: false,
  onClick: () => { },
};

export default ModalFooter;
